<template>
  <div>
    <b-card>
      <div>
        <h3>
          Wizyty i obłożenie czasowe
        </h3>

        <MonthlySummary
          :income_in_this_month="report.income_in_this_month"
          :income_month_ago="report.income_month_ago"
          :orders_in_this_month="report.orders_in_this_month"
          :orders_two_months_ago="report.orders_two_months_ago"
          :income_two_months_ago="report.income_two_months_ago"
          :income_three_months_ago="report.income_three_months_ago"
          :orders_three_months_ago="report.orders_three_months_ago"
          :orders_month_ago="report.orders_month_ago"
        />

      </div>
    </b-card>

    <b-card
      v-for="employee in employees"
      :key="employee"
    >
      <div class="employee">
        <div>
          <span class="employee-info">
            {{ employee.user.name }}
            <span class="employee-column">
              |
            </span>
          </span>
          <span class="employee-info">
            {{ employee.user.email }}
            <span class="employee-column">
              |
            </span>
          </span>
          <span class="employee-info">
            {{ employee.user.phone_number }}
            <span class="employee-column">
              |
            </span>
          </span>
        </div>
        <div>
          <router-link
            :to="{ name: 'DailySummary', params: { id: employee.user.id }}"
            class="btn btn-primary"
          >
            Podgląd
          </router-link>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import swal from 'sweetalert2'
import axios from 'axios'
import MonthlySummary from './MonthlySummary.vue'

export default {
  components: {
    BCard,
    BCardText,
    MonthlySummary,
  },
  data() {
    return {
      employees: [],
      report: {},
    }
  },
  created() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }

    // get root api url from env file
    const apiUrl = process.env.VUE_APP_API_URL
    axios.get(`${apiUrl}employee`, config)
      .then(response => {
        this.employees = response.data

        // foreach employee if email is too long, shorten it
        this.employees.forEach(employee => {
          if (employee.user.email.length > 15) {
            employee.user.email = `${employee.user.email.substring(0, 15)}...`
          }
          if (employee.user.name.length > 15) {
            employee.user.name = `${employee.user.name.substring(0, 15)}...`
          }
        })
      })
      .catch(error => {
        swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      })

    axios.get(`${apiUrl}Report`, config)
      .then(response => {
        this.report = response.data
      })
      .catch(error => {
        swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      })
  },
}

</script>

<style lang="scss">
.employee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.eye {
  margin-right: 10px;
  cursor: pointer;
}
.submit {
      width: 100%;
    height: 40px;
    border: 1px solid rgba(204, 204, 204, 0.344);
    border-radius: 5px;
    background: rgba(204, 204, 204, 0.468);
    margin-top: 10px;
}
/*mobile view*/
@media (max-width: 768px) {
  .employee-info {
    margin-bottom: 10px;
    display: block;
  }
  .employee-column {
    display: none;
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
